.more-menu .MuiList-padding {
  padding: 0 !important;
}

.more-menu .MuiListItem-button {
  margin: 0 !important;
}

button:focus {
  outline: none !important;
}

.dropdown-menu {
  top: 50% !important;
  left: 50% !important;
}

.pages li .Mui-selected {
  background-color: #2193b0 !important;
  border-radius: 50% !important;
}

.filter-column {
  margin: 0 0 0 20px !important;
  padding: 8px 12px 8px 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.filter-column:focus {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.filter-column span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #19233a;
  text-transform: none;
}

.filter-by {
  margin: 0 0 0 20px !important;
  padding: 8px 12px 8px 12px !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.filter-by:focus {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.filter-by span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #19233a;
  text-transform: none;
}

.filter-icon {
  padding: 0 0 0 40px;
}

.sort-icon {
  padding: 0 0 0 100px;
}

.status {
  padding: 8px 15px;
  border-radius: 30px;
}

.MuiTableCell-root {
  padding: 5px !important;
}

table {
  border-collapse: separate !important;
  /* border-spacing: 0px 0.5em !important; */
}
td:first-child,
th:first-child {
  border-radius: 5px 0 0 5px;
}
td:last-child,
th:last-child {
  border-radius: 0 5px 5px 0;
}
.pagination nav ul {
  justify-content: flex-end;
  padding: 20px 0;
}

.pagination nav ul li .Mui-selected {
  border-radius: 20px !important;
  border: 1px solid #2193b0;
  color: #2193b0;
  background: rgba(33, 147, 176, 0.1);
}

.add-user {
  padding: 8px 10px;
  color: #FAFAFA;
  font-weight: 600;
  font-size: 14px;
}

.user-fields {
  padding: 0 5px 0 5px;
}

.previewImage {
  background-size: cover !important;
}

.button {
  background: #2193b0 !important;
}

.button:hover {
  background-color: #2193b0 !important;
}

.buttonOutlined {
  border: 2px solid #2193b0 !important;
  color: #2193b0 !important;
  font-weight: 600 !important;
}

.buttonOutlined:focus {
  border: 2px solid #2193b0 !important;
}

.MuiButton-contained {
  box-shadow: none !important;
}

.popover-design {
  top: 35px !important;
}

.popover-design ul {
  padding: 5px 0 !important;
}

.popover-design .MuiPaper-rounded {
  border-bottom: 1px solid #2193b0;
  border-right: 1px solid #2193b0 !important;
  border-left: 1px solid #2193b0 !important;
  width: 160px;
  box-shadow: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.control input {
  height: 18px !important;
}

.control .a-select {
  height: 51px;
}

.divider {
  margin: 15px 30px !important;
}

.control label {
  font-size: 14px;
  font-weight: 500;
}

.image-upload-label {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #2193b0;
  background: rgba(0, 0, 0, 0.03);
  font-size: 14px;
  font-weight: 600;
  color: #00000099;
  cursor: pointer;
}

.camera-icon {
  position: absolute;
  top: 30%;
  left: 25%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #2193b0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FAFAFA;
  z-index: 2;
}

.image-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.mrvn-cln {
  border: 1px solid #2193b0 !important;
  color: #2193b0 !important;
  padding: 6px 20px !important;
  margin: 0 10px !important;
}

.mrvn-sv:hover {
  background-color: #2193b0 !important;
}

.mrvn-sv {
  background-color: #2193b0 !important;
  color: white !important;
  padding: 6px 15px;
}

.mrvn-sv {
  @apply bg-blue !important;
}
.mrvn-sv:hover {
  @apply bg-blue !important;
}

.mrvn-delete {
  background-color: #2193b0 !important;
  color: white !important;
  padding: 10px 15px !important;
  width: 180px !important;
}

.mrvn-delete:hover {
  background-color: #2193b0 !important;
}

.mrvn-del-cln {
  border: 1px solid #2193b0 !important;
  color: #2193b0 !important;
  padding: 10px 20px !important;
  margin: 0 10px !important;
  width: 180px !important;
}

.mrvn-del-cln2 {
  border: 1px solid #2193b0 !important;
  color: #2193b0 !important;
  padding: 6px 16px !important;
  margin: 0 10px !important;
}

.mrvn-del-cln:focus,
.mrvn-del-cln2:focus {
  border: 1px solid #2193b0 !important;
}

.mrvn-rst {
  font-weight: 600 !important;
  color: #ff0000 !important;
  border: 1px solid #ff0000 !important;
}

.mrvn-rst:focus {
  border: 1px solid #ff0000 !important;
}

.delete-icon {
  padding: 38px 15px;
  font-size: 0 !important;
  background: #ff00001a;
  border-radius: 100%;
}

.p-title {
  display: flex !important;
  align-items: center !important;
}

.MuiTableSortLabel-root {
  visibility: visible;
}

.disable-odd-even {
  background-color: transparent !important;
}

.content-right-sidebar ul{
   padding: 0 0px !important;
}

.content-right-sidebar .MuiListItem-gutters {
  padding-right: 0px !important;
}

.drawer-submenu-content {
  background-color: #FAFAFA !important;
  margin-top: 70px;
  border-left: 0px !important;
  right:auto !important;
  height: calc(100% - 60px);
  z-index: 0;
}

.content-editor .ql-editor {
  min-height: 200px !important;
}

.phrase-editor .ql-editor {
  min-height: 100px !important;
}

.content-perview {
  min-height: 560px !important;
}

.schedule-calendar .Mui-selected {
  background-color: rgb(33, 147, 176) !important;
  border-radius: 5px !important;
}

.schedule-calendar {
  color: rgb(33, 147, 176) !important;
}

.icon-tag:hover, .icon-tag.active {
  background-color: #f9ecff !important;
}

.post-preview:hover, .post-preview.active {
  background-color: #d9f0f6 !important;
}

.icon-user-activity:hover, .icon-user-activity.active {
  background-color: #e0f0f4 !important;
}

.icon-version-history:hover, .icon-version-history.active {
  background-color: #fbf1e3 !important;
}

.icon-visibility:hover, .icon-visibility.active {
  background-color: #eafae2 !important;
}

.icon-phrases:hover, .icon-phrases.active {
  background-color: #fee9e9 !important;
}

.icon-utilities:hover, .icon-utilities.active {
  background-color: #e8e4f0 !important;
}

.content-right-sidebar .MuiListItemText-primary{
  font-size: 12px;
  text-align: center;
  margin-right:10px;
}

.calendar-range-picker .MuiInputBase-root {
  font-size: 14px;
}

.calendar-range-picker .MuiOutlinedInput-notchedOutline {
   border-color: rgb(204 204 204 / 1) !important;
   border-width: 1px !important;
}

.fc-daygrid-event-harness{
  display: inline-flex;
  width: 30%;
}

.fc-timegrid-event-harness {
  display: inline-flex;
}

.fc-event {
  border: none;
  background-color:transparent;
}

.fc-h-event {
  border: none;
  background-color:transparent;
}

.fc-v-event {
  border: none;
  background-color:transparent;
}

.event-item-list-cal {
  width: 400px;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link {
  box-shadow: none;
}

.MuiDateCalendar-root .Mui-selected {
    background-color: #2193b0 !important;
}

.fc-daygrid-event-harness {
  width: 100% !important;
  margin-top: 0px !important;
}

.fc-event {
  width: 100% !important;
}

.calendar {
  min-width: 900px;
}

/* styles.css */
.info-button-container {
  position: relative;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.info-button {
  background-color: #2193b0;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  font-size: 12px;
}

.tooltip {
  position: absolute;
  background-color: #FAFAFA;
  color: #2193b0;
  border: #2193b0;
  padding: 5px 10px;
  border-radius: 5px;
  top: 100%;
  left: 0; /* Start from the left edge of the parent container */
  width: 25%; /* Extend horizontally */
  opacity: 0;
  transition: opacity 0.2s;
  white-space: nowrap; /* Prevent text from wrapping */
}

.info-button-container:hover .tooltip {
  opacity: 1;
}

.datepicker .MuiOutlinedInput-notchedOutline {
  border: none;
}

#textarea-emoji {
  top: 0px !important;
}

.ql-container {
  overflow-y: scroll !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.ql-container::-webkit-scrollbar {
  display: none !important;
}

.filepond--item {
  width: calc(25% - 0.5em);
}

.filepond--root,
.filepond--root .filepond--drop-label {
  min-height: 100px;
}

.MuiChip-icon{
  margin-left: 15px !important;
}

.filepond--root{
  margin-bottom: 0px !important;
}

.post-analytics-chart .MuiChartsAxis-tickLabel {
  transform: translateY(13px) rotate(45deg)
}