@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(./fonts/Montserrat-Regular.ttf) format("truetype");
}

* {
  font-family: "Montserrat";
}


.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.arrow-steps {
  display: flex;
  align-items: center;
}

.arrow-steps .step {
  flex: 1;
  font-size: 14px;
  text-align: center;
  color: #666;
  cursor: default;
  margin: 0 3px;
  padding: 8px 10px 8px 30px;
  float: left;
  position: relative;
  background-color: #eee !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
  content: " ";
  position: absolute;
  top: 0;
  right: -17px;
  width: 0;
  height: 0;
  border-top: 19px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #eee;
  z-index: 2;
  transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
  right: auto;
  left: 0;
  border-left: 17px solid #FAFAFA;
  z-index: 0;
}

.arrow-steps .step:first-child:before {
  border: none;
}

.arrow-steps .step span {
  position: relative;
}

.arrow-steps .step span:before {
  opacity: 0;
  content: "✔";
  position: absolute;
  top: -2px;
  left: -20px;
}

.arrow-steps .step.done span:before {
  opacity: 1;
  -webkit-transition: opacity 0.3s ease 0.5s;
  -moz-transition: opacity 0.3s ease 0.5s;
  -ms-transition: opacity 0.3s ease 0.5s;
  transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
  @apply text-white bg-blue !important;
}

/* .arrow-steps .step.current:after {
  border-left: 17px solid #2596be;
} */

.arrow-steps .step.current:after {
  border-left-width: 17px;
  border-left-style: solid;
  border-left-color: theme('colors.blue'); /* Using Tailwind's custom color */
}

.carousel {
  width: 100%;
  height: 100%;
}

.axis-vertical{
  width: 100%;
  height: 100% !important;
}


/* Animation for sliding */
@keyframes slide-next {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20%);
  }
}

@keyframes slide-previous {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(0);
  }
}

.text-diff-modified span.removed {
  background-color: lightcoral;
  text-decoration: line-through;
}

.text-diff-modified span.added {
  background-color: lightgreen;
}

/* Custom scroll bar */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2193b0;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2193b0;
}

.network-preview::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

.tui-image-editor-header-logo {
  display:none;
}

.tui-image-editor-header-buttons {
  display: none;
}